export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILED = 'GET_INVOICES_FAILED';

export const DELETE_FIXED_ASSET = 'DELETE_FIXED_ASSET';
export const DELETE_FIXED_ASSET_REQUEST = 'DELETE_FIXED_ASSET_REQUEST';
export const DELETE_FIXED_ASSET_SUCCESS = 'DELETE_FIXED_ASSET_SUCCESS';
export const DELETE_FIXED_ASSET_FAILED = 'DELETE_FIXED_ASSET_FAILED';

export const CREATE_FIXED_ASSET = 'CREATE_FIXED_ASSET';
export const CREATE_FIXED_ASSET_REQUEST = 'CREATE_FIXED_ASSET_REQUEST';
export const CREATE_FIXED_ASSET_SUCCESS = 'CREATE_FIXED_ASSET_SUCCESS';
export const CREATE_FIXED_ASSET_FAILED = 'CREATE_FIXED_ASSET_FAILED';

export const UPDATE_FIXED_ASSET = 'UPDATE_FIXED_ASSET';
export const UPDATE_FIXED_ASSET_REQUEST = 'UPDATE_FIXED_ASSET_REQUEST';
export const UPDATE_FIXED_ASSET_SUCCESS = 'UPDATE_FIXED_ASSET_SUCCESS';
export const UPDATE_FIXED_ASSET_FAILED = 'UPDATE_FIXED_ASSET_FAILED';

export const FETCH_NEW_FA_DATA = 'FETCH_NEW_FA_DATA';
export const FETCH_NEW_FA_DATA_REQUEST = 'FETCH_NEW_FA_DATA_REQUEST';
export const FETCH_NEW_FA_DATA_SUCCESS = 'FETCH_NEW_FA_DATA_SUCCESS';
export const FETCH_NEW_FA_DATA_FAILED = 'FETCH_NEW_FA_DATA_FAILED';

export const GET_PRODUCT_SUB_GROUPS = 'GET_PRODUCT_SUB_GROUPS';
export const GET_PRODUCT_SUB_GROUPS_REQUEST = 'GET_PRODUCT_SUB_GROUPS_REQUEST';
export const GET_PRODUCT_SUB_GROUPS_SUCCESS = 'GET_PRODUCT_SUB_GROUPS_SUCCESS';
export const GET_PRODUCT_SUB_GROUPS_FAILED = 'GET_PRODUCT_SUB_GROUPS_FAILED';

export const GET_PRODUCT_SUB_SUB_GROUPS = 'GET_PRODUCT_SUB_SUB_GROUPS';
export const GET_PRODUCT_SUB_SUB_GROUPS_REQUEST = 'GET_PRODUCT_SUB_SUB_GROUPS_REQUEST';
export const GET_PRODUCT_SUB_SUB_GROUPS_SUCCESS = 'GET_PRODUCT_SUB_SUB_GROUPS_SUCCESS';
export const GET_PRODUCT_SUB_SUB_GROUPS_FAILED = 'GET_PRODUCT_SUB_SUB_GROUPS_FAILED';

export const ERROR_OCCURRED = 'ERROR_OCCURRED';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const getInvoices = () => ({type: GET_INVOICES});

export const getInvoicesRequest = () => ({type: GET_INVOICES_REQUEST});

export const getInvoicesSuccess = (data) => ({type: GET_INVOICES_SUCCESS, data});

export const getInvoicesFailed = (data) => ({type: GET_INVOICES_FAILED, data});


export const deleteFixedAsset = (payload) => ({type: DELETE_FIXED_ASSET, payload});

export const deleteFixedAssetRequest = () => ({type: DELETE_FIXED_ASSET_REQUEST});

export const deleteFixedAssetSuccess = (data) => ({type: DELETE_FIXED_ASSET_SUCCESS, data});

export const deleteFixedAssetFailed = (data) => ({type: DELETE_FIXED_ASSET_FAILED, data});


export const createFixedAsset = (payload, cb) => ({type: CREATE_FIXED_ASSET, payload, cb});

export const createFixedAssetRequest = () => ({type: CREATE_FIXED_ASSET_REQUEST});

export const createFixedAssetSuccess = (data) => ({type: CREATE_FIXED_ASSET_SUCCESS, data});

export const createFixedAssetFailed = (data) => ({type: CREATE_FIXED_ASSET_FAILED, data});


export const updateFixedAsset = (id, payload, cb) => ({type: UPDATE_FIXED_ASSET, id, payload, cb});

export const updateFixedAssetRequest = () => ({type: UPDATE_FIXED_ASSET_REQUEST});

export const updateFixedAssetSuccess = (data) => ({type: UPDATE_FIXED_ASSET_SUCCESS, data});

export const updateFixedAssetFailed = (data) => ({type: UPDATE_FIXED_ASSET_FAILED, data});


export const fetchNewFaData = () => ({type: FETCH_NEW_FA_DATA});

export const fetchNewFaDataRequest = () => ({type: FETCH_NEW_FA_DATA_REQUEST});

export const fetchNewFaDataSuccess = (data) => ({type: FETCH_NEW_FA_DATA_SUCCESS, data});

export const fetchNewFaDataFailed = (data) => ({type: FETCH_NEW_FA_DATA_FAILED, data});


export const getProductSubGroups = (payload) => ({type: GET_PRODUCT_SUB_GROUPS, payload});

export const getProductSubGroupsRequest = () => ({type: GET_PRODUCT_SUB_GROUPS_REQUEST});

export const getProductSubGroupsSuccess = (data) => ({type: GET_PRODUCT_SUB_GROUPS_SUCCESS, data});

export const getProductSubGroupsFailed = (data) => ({type: GET_PRODUCT_SUB_GROUPS_FAILED, data});

export const getProductSubSubGroups = (payload) => ({type: GET_PRODUCT_SUB_SUB_GROUPS, payload});

export const getProductSubSubGroupsRequest = () => ({type: GET_PRODUCT_SUB_SUB_GROUPS_REQUEST});

export const getProductSubSubGroupsSuccess = (data) => ({type: GET_PRODUCT_SUB_SUB_GROUPS_SUCCESS, data});

export const getProductSubSubGroupsFailed = (data) => ({type: GET_PRODUCT_SUB_SUB_GROUPS_FAILED, data});


export const errorOccurred = payload => ({type: ERROR_OCCURRED, payload});

export const clearError = () => ({type: CLEAR_ERROR});
