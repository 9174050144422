import {
    GET_ORDERS,
    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAILED,
    SET_FILTERED_ORDERS,
    SEARCH_ORDER,
    SEARCH_ORDER_REQUEST,
    SEARCH_ORDER_SUCCESS,
    SEARCH_ORDER_FAILED,
    APPLY_ORDER,
    APPLY_ORDER_REQUEST,
    APPLY_ORDER_SUCCESS,
    APPLY_ORDER_FAILED,
    SET_LOADING,
    ERROR_OCCURRED,
    CLEAR_ERROR
} from '../actions/Order.actions';

const orderState = {
    orders: null,
    filteredOrders: null,
    order: null,
    loading: null,
    error: null
}

const CoreReducer = (state = orderState, action) => {
    const {type, payload, data} = action;

    switch (type) {
        case GET_ORDERS:
            return {...state};
        case GET_ORDERS_REQUEST:
            return {
                ...state,
                orders: null,
                filteredOrders: null,
                loading: true,
                error: false
            };
        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: data,
                filteredOrders: data,
                loading: false,
                error: false
            };
        case GET_ORDERS_FAILED:
            return {
                ...state,
                orders: null,
                filteredOrders: null,
                loading: false,
                error: data
            };
        case SET_FILTERED_ORDERS:
            return {
                ...state,
                filteredOrders: data
            };
        case SEARCH_ORDER:
            return {...state};
        case SEARCH_ORDER_REQUEST:
            return {
                ...state,
                order: null,
                loading: true,
                error: false
            };
        case SEARCH_ORDER_SUCCESS:
            return {
                ...state,
                order: data,
                loading: false,
                error: false
            };
        case SEARCH_ORDER_FAILED:
            return {
                ...state,
                order: null,
                loading: false,
                error: data
            };
        case APPLY_ORDER:
            return {...state};
        case APPLY_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            };
        case APPLY_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case APPLY_ORDER_FAILED:
            return {
                ...state,
                loading: false,
                error: data
            };
        case SET_LOADING:
        case ERROR_OCCURRED:
            return {
                ...state,
                ...payload
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

export default CoreReducer;
