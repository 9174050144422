import React, {useEffect, useState} from 'react';

// Utils
import setAuthToken from "@utils/setAuthToken";
import {ConfigProvider as AntdConfigProvider} from 'antd';
import trTR from 'antd/locale/tr_TR';

// Redux
import {Provider as ReduxProvider} from 'react-redux';
import {persistor, store} from '@redux/store';
import {PersistGate} from 'redux-persist/integration/react';

// SCSS
import './App.css';
import 'antd/dist/reset.css';
import '@styles/global.scss';

// Routing
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import RequireAuth from "@components/organisms/RequireAuth";

// Pages
import LoginPage from '@pages/Login';
import DashboardPage from '@pages/Dashboard';
import NewFixedAssetPage from "@pages/NewFixedAsset";
import FixedAssetPage from "@pages/FixedAsset";
import DeliverPage from "@pages/Deliver";
import {API} from "@utils/axiosInstances";

function App() {

    const [isAuthenticated, setUserHasAuthenticated] = useState(undefined);
    const [user, setUser] = useState(undefined);

    const auth = async () => {
        const token = localStorage.getItem('imicryl_expo_token');
        try {
            const res = await API.get(`/imicryl-expo/me`);
            console.log('ME', res?.data);
            if (res?.data?.token) {
                setUserHasAuthenticated(true);
                setAuthToken(res?.data?.token);
                setUser(res?.data);
            } else {
                localStorage.removeItem('imicryl_expo_token');
                setUserHasAuthenticated(false);
            }

        } catch (e) {
            console.log('error?', e);
            setUserHasAuthenticated(false);
        }
    }

    useEffect(() => {
        auth();
    }, []);

    return (
        <ReduxProvider store={store}>
            <PersistGate persistor={persistor}>
                <AntdConfigProvider locale={trTR} theme={{
                    token: {
                        fontFamily: 'Kanit',
                        colorPrimary: '#2D6DB7',
                        colorBorderSecondary: '#F1F6FB',
                        colorFillSecondary: '#F1F6FB',
                        colorTextSecondary: '#F1F6FB'
                    },
                }}>
                    <Router>
                        <Routes>
                            <Route path="/login" element={<LoginPage/>}/>
                            <Route path="/" element={
                                <RequireAuth user={user}
                                             isAuthenticated={isAuthenticated}>
                                    <DeliverPage/>
                                </RequireAuth>
                            }/>
                            <Route path="/siparisler" element={
                                <RequireAuth user={user}
                                             isAuthenticated={isAuthenticated}>
                                    <DashboardPage/>
                                </RequireAuth>
                            }/>
                        </Routes>
                    </Router>
                </AntdConfigProvider>
            </PersistGate>
        </ReduxProvider>
    );
}

export default App;
