import React from 'react';
import styles from '@styles/components/Navbar.module.scss';
import {useDispatch} from "react-redux";
import {logout} from "@redux/actions/Auth.actions";
import {Link} from "react-router-dom";

const Navbar: React.FC<any> = () => {
    const dispatch = useDispatch();

    const logoutAction = () => {
        dispatch(logout());
    }

    return <nav className={styles.navbar}>
        <div className={styles.left_bar}>
            <Link to={`/`}><img src={"/imi.png"} height={20} width={80}/></Link>
        </div>
        <ul className={styles.right_bar}>
            <li><Link to={`/`}>Teslim Et</Link></li>
            <li><Link to={`/siparisler`}>Siparişler</Link></li>
            <li onClick={logoutAction}><Link to={`/login`}>Çıkış Yap</Link></li>
        </ul>
    </nav>
}

export default Navbar;
