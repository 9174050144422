import {notification} from "antd";

const DEFAULT_TYPE_MESSAGES = {
    'success': 'İşlem başarılı',
    'error': 'Bir hata ile karşılaşıldı'
}

const alert = (type, config) => {
    notification.open({
        type: type || config.type || 'error',
        duration: config.duration || 3,
        message: config.message || DEFAULT_TYPE_MESSAGES[type] || null,
        description: config.description || null,
        placement: config.placement || 'topRight'
    });
}

export default alert;
