import {API} from '@utils/axiosInstances';

import {
    GET_COMPANIES,
    getCompaniesRequest,
    getCompaniesSuccess,
    getCompaniesFailed,
    GET_BRANCHES,
    getBranchesRequest,
    getBranchesSuccess,
    getBranchesFailed,
    GET_UNITS,
    getUnitsRequest,
    getUnitsSuccess,
    getUnitsFailed,
    GET_SUB_UNITS,
    getSubUnitsRequest,
    getSubUnitsSuccess,
    getSubUnitsFailed
} from "@redux/actions/Core.actions";

import {ERROR_RESPONSE} from "@utils/constants/error.config";
import {takeLatest, put, call} from 'redux-saga/effects';

export function* getCompanies() {
    yield takeLatest(GET_COMPANIES, getCompaniesAsync);
}

export function* getBranches() {
    yield takeLatest(GET_BRANCHES, getBranchesAsync);
}

export function* getUnits() {
    yield takeLatest(GET_UNITS, getUnitsAsync);
}

export function* getSubUnits() {
    yield takeLatest(GET_SUB_UNITS, getSubUnitsAsync);
}

export function* getCompaniesAsync() {
    try {
        yield put(getCompaniesRequest());
        const response = yield call(() => API.get('/companies'));
        if (response.data) {
            yield put(getCompaniesSuccess(response?.data));
        }
    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                yield put(getCompaniesFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(getCompaniesFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* getBranchesAsync(action) {
    try {
        yield put(getBranchesRequest());
        const response = yield call(() => API.get('/branches/' + action.companyId));
        if (response.data) {
            yield put(getBranchesSuccess(response?.data));
        }
    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                yield put(getBranchesFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(getBranchesFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* getUnitsAsync(action) {
    try {
        yield put(getUnitsRequest());
        const response = yield call(() => API.get(`/units/${action.companyId}/${action.branchId}`));
        if (response.data) {
            yield put(getUnitsSuccess(response?.data));
        }
    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                yield put(getUnitsFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(getUnitsFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* getSubUnitsAsync(action) {
    try {
        yield put(getSubUnitsRequest());
        const response = yield call(() => API.get(`/sub-units/${action.unitId}`));
        if (response.data) {
            yield put(getSubUnitsSuccess(response?.data));
        }
    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                yield put(getSubUnitsFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(getSubUnitsFailed(ERROR_RESPONSE(error)));
        }
    }
}
