import {API} from '@utils/axiosInstances';
import alert from '@utils/alert';

import {
    GET_ORDERS,
    getOrdersRequest,
    getOrdersSuccess,
    getOrdersFailed,
    SEARCH_ORDER,
    searchOrder as searchOrderAction,
    searchOrderRequest,
    searchOrderSuccess,
    searchOrderFailed,
    APPLY_ORDER,
    applyOrderRequest,
    applyOrderSuccess,
    applyOrderFailed,
} from "@redux/actions/Order.actions";

import {ERROR_RESPONSE} from "@utils/constants/error.config";
import {takeLatest, put, call, select} from 'redux-saga/effects';

export function* getOrders() {
    yield takeLatest(GET_ORDERS, getOrdersAsync);
}

export function* searchOrder() {
    yield takeLatest(SEARCH_ORDER, searchOrderAsync);
}

export function* applyOrder() {
    yield takeLatest(APPLY_ORDER, applyOrderAsync);
}

export function* getOrdersAsync(action) {
    try {
        yield put(getOrdersRequest());
        const response = yield call(() => API.get(`/imicryl-expo/invoices`));
        if (response.data) {
            yield put(getOrdersSuccess(response?.data));

            if (action.cb) {
                action.cb();
            }
        }
    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                yield put(getOrdersFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(getOrdersFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* searchOrderAsync(action) {
    try {
        yield put(searchOrderRequest());
        const response = yield call(() => API.put(`/imicryl-expo/orders`, {
            idexCode: action.payload
        }));
        if (response.data) {
            yield put(searchOrderSuccess(response?.data));
        }
    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                alert('error', {description: response.data.message, placement: 'bottomRight'});
                yield put(searchOrderFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(searchOrderFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* applyOrderAsync(action) {
    try {
        console.log('HELLO');
        // const order = yield select(state => state.order.order);
        const order = action.payload;
        console.log('APPLY ORDER', order);

        yield put(applyOrderRequest());
        const response = yield call(() => API.put(`/imicryl-expo/orders/apply`, {
            id: order.idex_id
        }));
        console.log('APPLY RESPONSE', response);
        if (response.data) {
            alert('success', {description: 'Ürün başarılı bir şekilde teslim edildi.', placement: 'bottomRight'});
            yield put(applyOrderSuccess(response?.data));
            yield put(searchOrderAction(order.idex_code));

            if (action.cb) {
                action.cb();
            }
        }
    } catch (error) {
        console.log('HELLO ERROR', error);
        const {response} = error;
        if (response) {
            if (response.data.error) {
                alert('error', {description: response.data.message, placement: 'bottomRight'});
                yield put(applyOrderFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(applyOrderFailed(ERROR_RESPONSE(error)));
        }
    }
}
