import axios from 'axios';

export const ENVIRONMENT_TAG = process.env.REACT_APP_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const PROXY_URL = '/api';

console.log('API URL', API_URL);

export const API = axios.create({
    baseURL: API_URL,
    timeout: 10000,
    withCredentials: false,
    headers: {
        "Content-Type": "application/json",
        'x-imicryl-expo-token': localStorage.getItem('imicryl_expo_token')
    }
});
