import React, {useEffect, useState} from 'react';
import styles from '@styles/pages/Dashboard.module.scss';
import {useDispatch, useSelector} from "react-redux";
import Table from "@components/organisms/Table";
import Layout from "@components/organisms/Layout";
import {applyOrder, getOrders, setFilteredOrders} from "@redux/actions/Order.actions";
import {Button, Input, notification} from "antd";
import openNotification from "@utils/openNotification";
import scrollToTop from "@utils/scrollToTop";
import moment from 'moment';
import {OWNERS_BY_TOKEN} from "@utils/constants/transactionTokenOwners.config";

const DashboardPage: React.FC<any> = () => {
    const dispatch = useDispatch();
    const {orders, filteredOrders} = useSelector((state: any) => state.order);

    const [notificationApi, notificationContextHolder] = notification.useNotification();
    const [currentFilterOption, setCurrentFilterOption] = useState(undefined);

    useEffect(() => {
        dispatch(getOrders(null));
        scrollToTop();
    }, []);

    const filterOrdersByOption = (type) => {

        let filteredOrders = [];

        if (type === 'is_delivered') {
            filteredOrders = orders?.filter(el => el.is_delivered === 1);
        } else if (type === 'is_not_delivered') {
            filteredOrders = orders?.filter(el => el.is_delivered !== 1);
        } else {
            filteredOrders = orders;
        }

        dispatch(setFilteredOrders(filteredOrders));

    }

    useEffect(() => {
        filterOrdersByOption(currentFilterOption);
    }, [currentFilterOption]);

    const searchOrders = (e) => {
        const text = e.target.value;

        if (text === '' || !text) {
            dispatch(setFilteredOrders(orders));
        } else {
            dispatch(setFilteredOrders(orders?.filter((order) => {
                if ((order.user_tckn?.toString().includes(text) || order.tckn?.toString().includes(text) || (order.tax_number?.toString().includes(text)) || order.idex_code?.toString().includes(text)) || (`${order.firstname.toLowerCase()} ${order.lastname.toLowerCase()}`.includes(text))) {
                    return order;
                }
                return false;
            })));

        }
    }

    const deliverOrder = (order) => {
        openNotification({
            api: notificationApi,
            title: 'Emin Misiniz ?',
            description: 'Bu ürünü teslim etmek istediğinize emin misiniz?',
            onAccept: () => {
                dispatch(applyOrder({
                    idex_id: order.idex_id,
                    idex_code: order.idex_code
                }, () => {
                    dispatch(getOrders(() => {
                        filterOrdersByOption(currentFilterOption);
                    }));
                }));
            },
            onAcceptTitle: 'Teslim Et',
            onDecline: null,
            onDeclineTitle: 'Vazgeç'
        });
    }


    return <Layout>
        <section className={styles.dashboard}>
            <div className={`flex gap-5`}>
                <Button type={"link"} onClick={() => setCurrentFilterOption(null)}>Tümü</Button>
                <Button type={"link"} onClick={() => setCurrentFilterOption('is_delivered')}>Teslim Edilenler</Button>
                <Button type={"link"} onClick={() => setCurrentFilterOption('is_not_delivered')}>Bekleyenler</Button>
            </div>
            {/*
            <div className={styles.stats_mobile}>
                <div className={styles.stat}>
                    <div className={styles.block}>
                        <h4 className={styles.title}>Sipariş</h4>
                        <h5 className={styles.content}>15</h5>
                    </div>
                </div>
                <div className={styles.stat}>
                    <div className={styles.block}>
                        <h4 className={styles.title}>Müşteri</h4>
                        <h5 className={styles.content}>15</h5>
                    </div>
                </div>
                <div className={styles.stat}>
                    <div className={styles.block}>
                        <h4 className={styles.title}>Ürün</h4>
                        <h5 className={styles.content}>23</h5>
                    </div>
                </div>
            </div>
            */}
            <div className={styles.fa_table}>
                <div className={styles.actions}>
                    <div className={styles.search}>
                        <label>Ara: </label>
                        <Input type={"text"} onChange={searchOrders}/>
                    </div>
                </div>
                <Table columns={
                    [
                        {
                            title: 'Teslimat Kodu',
                            dataIndex: 'idex_code',
                            key: 'idex_code',
                            render: (_, {
                                idex_code
                            }) => (<>{idex_code}</>)
                        },
                        {
                            title: 'Müşteri',
                            dataIndex: 'employeeName',
                            key: 'employeeName',
                            render: (_, {
                                firstname,
                                lastname
                            }) => (<>{firstname} {lastname}</>)
                        },
                        {
                            title: 'TCKN/Vergi No',
                            dataIndex: 'image',
                            key: 'tckn',
                            render: (_, {user_tckn, tckn, tax_number}) => (
                                <>{user_tckn || tckn || tax_number}</>)
                        },
                        {
                            title: 'Ürün',
                            dataIndex: 'image',
                            key: 'image',
                            render: (_, {}) => (
                                <div className={"d-flex ai-center gap-10"}>
                                    <img alt={`fa-thumbnail`}
                                         src={'https://d3hgqa7jiqm918.cloudfront.net/products/1684894150688.webp'}
                                         height={50} width={50}/>
                                    <span>IDEX Imicryl Nova Compo C Nanohibrit Üniversal Kompozit</span>
                                </div>)
                        },
                        {
                            title: 'Kod Tarihi',
                            dataIndex: 'createdAt',
                            key: 'createdAt',
                            render: (_, {created_at}) => (<>{moment(created_at).format('DD.MM.YYYY HH:mm') || '-'}</>)
                        },
                        {
                            title: 'Teslimat Durumu',
                            dataIndex: 'is_delivered',
                            key: 'is_delivered',
                            render: (_, {is_delivered}) => (<>{is_delivered === 0 ?
                                <span className={'color-primary-orange'}>Teslim Edilecek</span> :
                                <span className={'color-primary-green'}>Teslim Edildi</span>}</>)
                        },
                        {
                            title: 'Teslim Tarihi',
                            dataIndex: 'delivered_time',
                            key: 'delivered_time',
                            render: (_, {delivered_time}) => delivered_time ? moment(delivered_time).format('DD.MM.YYYY HH:mm') : '-'
                        },
                        {
                            title: 'Teslim Eden',
                            dataIndex: 'transaction_token',
                            key: 'transaction_token',
                            render: (_, {transaction_token}) => OWNERS_BY_TOKEN[transaction_token] || '-'
                        },
                        {
                            title: 'İşlemler',
                            dataIndex: 'actions',
                            key: 'actions',
                            render: (_, {is_delivered, idex_id, idex_code}) => (
                                is_delivered !== 1 ? <Button onClick={() => deliverOrder({
                                    idex_id: idex_id,
                                    idex_code: idex_code
                                })}>
                                    Teslim Et
                                </Button> : '-'
                            )
                        }
                    ]
                } data={filteredOrders?.map((el, i) => ({...el, key: i}))}/>
            </div>

            <div className={styles.fa_cards}>
                <div className={styles.actions}>
                    <div className={`${styles.search} d-flex ai-center gap-5`}>
                        <label>Ara: </label>
                        <Input type={"text"} onChange={searchOrders}/>
                    </div>
                </div>
                {filteredOrders?.map((order, i) => (
                    <div key={i} className={styles.card}>
                        <div className={styles.side}>
                            <div className={styles.image}>
                                <img
                                    src={'https://d3hgqa7jiqm918.cloudfront.net/products/1684894150688.webp'}
                                    height={50} width={75}/>
                            </div>
                            <div className={styles.actions}>
                                {
                                    order?.is_delivered !== 1 ?
                                        <Button onClick={() => deliverOrder({
                                            idex_id: order.idex_id,
                                            idex_code: order.idex_code
                                        })}>Teslim Et</Button>
                                        : '-'
                                }
                            </div>
                        </div>
                        <div className={styles.content}>
                            <h1><span
                                className={"color-primary-orange"}>{order?.idex_code}</span> | {order?.firstname} {order?.lastname}
                            </h1>
                            <h3>TCKN/Vergi No: <u>{order?.user_tckn || order?.tckn || order?.tax_number}</u></h3>

                            <h3>Kod Tarihi: <u>{moment(order?.created_at).format('DD.MM.YYYY HH:mm') || '-'}</u></h3>


                            <h3>Teslimat Durumu: <u>{order?.is_delivered === 0 ?
                                <span className={'color-primary-orange'}>Teslim Edilecek</span> :
                                <span className={'color-primary-green'}>Teslim Edildi</span>}</u></h3>

                            {order?.is_delivered === 1 &&
                              <>
                                <h3>Teslimat
                                  Tarihi: <u>{moment(order?.delivered_time).format('DD.MM.YYYY HH:mm') || '-'}</u></h3>

                                <h3>Teslim Eden: <u>{OWNERS_BY_TOKEN[order?.transaction_token] || '-'}</u></h3>
                              </>
                            }
                        </div>
                    </div>
                ))}
            </div>

            {notificationContextHolder}

        </section>
    </Layout>
}

export default DashboardPage;
