import React, {useEffect, useState} from 'react';
import styles from '@styles/pages/Deliver.module.scss';
import {useDispatch, useSelector} from "react-redux";

import moment from 'moment';

import {AudioOutlined, LoadingOutlined, UpSquareFilled} from '@ant-design/icons';
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition';

import Layout from "@components/organisms/Layout";
import {Button, Divider, Input, Spin} from "antd";
import {applyOrder, searchOrder} from "@redux/actions/Order.actions";
import {OWNERS_BY_TOKEN} from "@utils/constants/transactionTokenOwners.config";

const DeliverPage: React.FC<any> = () => {
    const dispatch = useDispatch();
    const {order, loading} = useSelector((state: any) => state.order);
    const [searchText, setSearchText] = useState('');

    const onSearch = (text) => {
        if (text !== '') {
            dispatch(searchOrder(text));
        }
    }

    const onOrderApplied = () => {
        dispatch(applyOrder({
            idex_id: order.idex_id,
            idex_code: order.idex_code
        }, null));
    }

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    useEffect(() => {
        console.log('LISTENING', listening);
        if (listening === true) {
            setSearchText('');
        } else {
            const text = transcript.replaceAll(' ', '');
            console.log('TEXT', text);
            setSearchText(text);
            if (text && /^-?\d+$/.test(text)) {
                dispatch(searchOrder(text));
            }
        }
    }, [listening]);

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    return <Layout>
        <section className={styles.wrapper}>


            <div className={styles.search_form}>
                <h3>Hediyeli Ürün Teslimatı</h3>
                <Input.Search placeholder="Lütfen teslim kodu giriniz"
                              enterButton="Bul"
                              type={"number"}
                              pattern="[0-9]"
                              maxLength={6}
                              max={6}
                              inputMode={"numeric"}
                              size="large"
                              value={searchText}
                              onChange={(e) => {
                                  if (Number.isNaN(e.target.value)) return;
                                  const n = e.target.value.replace(/[^\d]/g, '');
                                  setSearchText(n);
                              }}
                              suffix={/*listening === true ? <UpSquareFilled style={{
                                  color: 'red',
                                  backgroundColor: 'red',
                                  fill: 'red',
                              }} onClick={() => {
                                  SpeechRecognition.stopListening();
                              }}/> : <AudioOutlined
                                  onClick={() => {
                                      SpeechRecognition.startListening({language: 'tr'});
                                  }}
                                  style={{
                                      fontSize: 16,
                                      color: listening === true ? 'red' : '#1890ff'
                                  }}
                              />*/undefined}
                              onSearch={onSearch}/>
            </div>

            <div style={{display: 'none'}}>
                <div>
                    <p>Microphone: {listening ? 'on' : 'off'}</p>
                    <button onClick={() => {
                        SpeechRecognition.startListening({language: 'tr'});
                    }}>Start
                    </button>
                    <button onClick={SpeechRecognition.stopListening}>Stop</button>
                    <button onClick={resetTranscript}>Reset</button>
                    <p>{transcript}</p>
                </div>
            </div>

            <Spin spinning={loading} style={{minHeight: 200}}
                  indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}>
                {order &&
                    (Array.isArray(order) ? <>Sipariş Bulunamadı.</> :
                        <div className={styles.found_order}>
                            <label>Sipariş No:</label> <h3>{order?.order_id}</h3>
                            <label>Müşteri Adı:</label> <h3>{order?.firstname} {order?.lastname}</h3>
                            <label>TCKN/Vergi No:</label>
                            <h3>{order?.user_tckn || order?.tckn || order?.tax_number}</h3>
                            <label>Kod Oluşturulma Tarihi:</label>
                            <h3>{moment(order?.created_at).format('DD.MM.YYYY HH:mm') || '-'}</h3>
                            <label>Durum:</label>
                            <h3>{order?.is_delivered === 0 ?
                                <span className={'color-primary-orange'}>Teslim Edilecek</span> :
                                <span className={'color-primary-green'}>Teslim Edildi</span>}</h3>
                            <Divider/>
                            <label>Teslim Edilecek Ürün:</label>
                            <div className={"d-flex ai-center gap-20"}>
                                <h3>IDEX Imicryl Nova Compo C Nanohibrit Üniversal Kompozit</h3>
                                <img alt={"teslim-edilecek-urun"}
                                     src={'https://d3hgqa7jiqm918.cloudfront.net/products/1684894150688.webp'}
                                     height={100} width={100}/>
                            </div>

                            <div className={styles.buttons}>
                                {order.is_delivered === 0 &&
                                  <Button type={"primary"} color={"white"} onClick={onOrderApplied}
                                          className={styles.deliver_button}>Teslim Et</Button>
                                }
                            </div>

                            {order?.is_delivered === 1 &&
                              <>
                                <Divider/>
                                <label>Teslimat Tarihi:</label>
                                <h3>{moment(order?.delivered_time).format('DD.MM.YYYY HH:mm') || '-'}</h3>
                                <label>Teslim Eden:</label>
                                <h3>{OWNERS_BY_TOKEN[order?.transaction_token] || '-'}</h3>
                              </>
                            }
                        </div>)
                }
            </Spin>
        </section>
    </Layout>
}

// @ts-ignore
export default DeliverPage;
