import {
    GET_INVOICES,
    GET_INVOICES_REQUEST,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_FAILED,
    DELETE_FIXED_ASSET,
    DELETE_FIXED_ASSET_REQUEST,
    DELETE_FIXED_ASSET_SUCCESS,
    DELETE_FIXED_ASSET_FAILED,
    FETCH_NEW_FA_DATA,
    FETCH_NEW_FA_DATA_REQUEST,
    FETCH_NEW_FA_DATA_SUCCESS,
    FETCH_NEW_FA_DATA_FAILED,
    GET_PRODUCT_SUB_GROUPS,
    GET_PRODUCT_SUB_GROUPS_REQUEST,
    GET_PRODUCT_SUB_GROUPS_SUCCESS,
    GET_PRODUCT_SUB_GROUPS_FAILED,
    GET_PRODUCT_SUB_SUB_GROUPS,
    GET_PRODUCT_SUB_SUB_GROUPS_REQUEST,
    GET_PRODUCT_SUB_SUB_GROUPS_SUCCESS,
    GET_PRODUCT_SUB_SUB_GROUPS_FAILED,
    CREATE_FIXED_ASSET,
    CREATE_FIXED_ASSET_REQUEST,
    CREATE_FIXED_ASSET_SUCCESS,
    CREATE_FIXED_ASSET_FAILED,
    UPDATE_FIXED_ASSET,
    UPDATE_FIXED_ASSET_REQUEST,
    UPDATE_FIXED_ASSET_SUCCESS,
    UPDATE_FIXED_ASSET_FAILED,
    ERROR_OCCURRED,
    CLEAR_ERROR
} from '../actions/FixedAssets.actions';

const fixedAssetState = {
    employees: null,
    productGroups: null,
    productSubGroups: null,
    productSubSubGroups: null,
    newFaForm: {
        barcode: "",
        productStatus: "",
        productGroup: null,
        productSubGroup: null,
        productSubSubGroup: null,
        brand: "",
        model: "",
        employee: null,
        serialNo: "",
        expenseType: "",
        description: "",
        description2: "",
        image: undefined,
        branchId: null,
        unit: null,
        subUnit: null
    },
    invoices: null,
    loading: null,
    error: null
}

const CoreReducer = (state = fixedAssetState, action) => {
    const {type, payload, data} = action;

    switch (type) {
        case GET_INVOICES:
            return {...state};
        case GET_INVOICES_REQUEST:
            return {
                ...state,
                invoices: null,
                loading: true,
                error: false
            };
        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: data,
                loading: false,
                error: false
            };
        case GET_INVOICES_FAILED:
            return {
                ...state,
                invoices: null,
                loading: false,
                error: data
            };
        case DELETE_FIXED_ASSET:
            return {...state};
        case DELETE_FIXED_ASSET_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            };
        case DELETE_FIXED_ASSET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case DELETE_FIXED_ASSET_FAILED:
            return {
                ...state,
                loading: false,
                error: data
            };
        case FETCH_NEW_FA_DATA:
            return {...state};
        case FETCH_NEW_FA_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            };
        case FETCH_NEW_FA_DATA_SUCCESS:
            return {
                ...state,
                employees: data.employees,
                productGroups: data.productGroups,
                productSubGroups: data.productSubGroups,
                productSubSubGroups: data.productSubSubGroups,
                loading: false,
                error: false
            };
        case FETCH_NEW_FA_DATA_FAILED:
            return {
                ...state,
                employees: null,
                productGroups: null,
                productSubGroups: null,
                productSubSubGroups: null,
                loading: false,
                error: data
            };
        case GET_PRODUCT_SUB_GROUPS:
            return {...state};
        case GET_PRODUCT_SUB_GROUPS_REQUEST:
            return {
                ...state,
                productSubGroups: null,
                loading: true,
                error: false
            };
        case GET_PRODUCT_SUB_GROUPS_SUCCESS:
            return {
                ...state,
                productSubGroups: data,
                loading: false,
                error: false
            };
        case GET_PRODUCT_SUB_GROUPS_FAILED:
            return {
                ...state,
                productSubGroups: null,
                loading: false,
                error: data
            };
        case GET_PRODUCT_SUB_SUB_GROUPS:
            return {...state};
        case GET_PRODUCT_SUB_SUB_GROUPS_REQUEST:
            return {
                ...state,
                productSubSubGroups: null,
                loading: true,
                error: false
            };
        case GET_PRODUCT_SUB_SUB_GROUPS_SUCCESS:
            return {
                ...state,
                productSubSubGroups: data,
                loading: false,
                error: false
            };
        case GET_PRODUCT_SUB_SUB_GROUPS_FAILED:
            return {
                ...state,
                productSubSubGroups: null,
                loading: false,
                error: data
            };
        case CREATE_FIXED_ASSET:
            return {...state};
        case CREATE_FIXED_ASSET_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            };
        case CREATE_FIXED_ASSET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case CREATE_FIXED_ASSET_FAILED:
            return {
                ...state,
                loading: false,
                error: data
            };
        case UPDATE_FIXED_ASSET:
            return {...state};
        case UPDATE_FIXED_ASSET_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            };
        case UPDATE_FIXED_ASSET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };
        case UPDATE_FIXED_ASSET_FAILED:
            return {
                ...state,
                loading: false,
                error: data
            };
        case ERROR_OCCURRED:
            return {
                ...state,
                ...payload
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

export default CoreReducer;
