import React, {useEffect} from 'react';

import styles from '@styles/pages/Login.module.scss';

import {useDispatch} from "react-redux";
import {login} from "@redux/actions/Auth.actions";
import {Button, Divider, Form, Input} from "antd";
import {useNavigate} from "react-router";

import scrollToTop from "@utils/scrollToTop";


const LoginPage: React.FC<any> = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const token = localStorage.getItem('imicryl_expo_token');

    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token]);

    useEffect(() => {
        scrollToTop();
    }, []);

    const onFinish = (loginForm: any) => {
        dispatch(login({
            ...loginForm
        }, () => {
            window.location.reload();
        }));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return <section className={styles.login}>

        <div className={styles.logo}>
            <img src={"/dpLogo.jpg"} height={30} width={120}/>
            <Divider style={{backgroundColor: 'black', height: '1.5em', marginInline: 20}}
                     type={'vertical'}/>
            <img src={"/imi.png"} height={30} width={120}/>
        </div>

        <div className={`${styles.container} p-30`}>

            <h3 style={{textAlign: 'center'}}>Imicryl - Dentalpiyasa Paneli</h3>

            <Form
                name="basic"
                className={styles.login_form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 750}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Şifre"
                    name="password"
                    className={styles.login_input}
                    rules={[{required: true, message: 'Lütfen şifrenizi giriniz.'}]}
                >
                    <Input.Password inputMode={"numeric"} allowClear/>
                </Form.Item>

                <Form.Item className={"d-flex jc-end w-100 mb-10"}>
                    <Button type="primary" htmlType="submit">
                        Giriş Yap
                    </Button>
                </Form.Item>

            </Form>
        </div>
    </section>
}

export default LoginPage;
