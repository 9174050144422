import {all} from "redux-saga/effects";

import {
    login,
    logout,
    getMe,
    validateEmail,
    register,
    changePassword,
    forgotPassword,
    forgotPasswordVerify,
} from '@redux/sagas/auth';

import {getBranches, getCompanies, getSubUnits, getUnits} from "@redux/sagas/core";

import {
    getInvoices,
    deleteFixedAsset,
    createFixedAsset,
    updateFixedAsset,
    fetchNewFaData,
    getProductSubGroups,
    getProductSubSubGroups
} from "@redux/sagas/fixedAssets";

import {getOrders, searchOrder, applyOrder} from "@redux/sagas/order";

export default function* rootSaga() {
    yield all([
        //  AUTH
        login(), logout(), getMe(), validateEmail(), register(), changePassword(), forgotPassword(), forgotPasswordVerify(),
        //  CORE
        getCompanies(), getBranches(), getUnits(), getSubUnits(),
        // FIXED ASSETS
        getInvoices(), deleteFixedAsset(), createFixedAsset(), updateFixedAsset(), fetchNewFaData(), getProductSubGroups(), getProductSubSubGroups(),
        // ORDERS
        getOrders(), searchOrder(), applyOrder()
    ]);
}

