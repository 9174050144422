import {Fragment, useEffect} from "react";
import Login from '@pages/Login';
import {useSelector} from "react-redux";
import CountingLocation from "@pages/CountingLocation";

function RequireAuth({
                         children,
                         isAuthenticated
                     }: { children: JSX.Element, isAuthenticated: boolean, user: any }) {

    if (isAuthenticated === undefined) {
        return <Fragment/>;
    } else {
        return (isAuthenticated ? children : <Login></Login>)
    }
}

export default RequireAuth;
