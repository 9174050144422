export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED';

export const SET_FILTERED_ORDERS = 'SET_FILTERED_ORDERS';

export const SEARCH_ORDER = 'SEARCH_ORDER';
export const SEARCH_ORDER_REQUEST = 'SEARCH_ORDER_REQUEST';
export const SEARCH_ORDER_SUCCESS = 'SEARCH_ORDER_SUCCESS';
export const SEARCH_ORDER_FAILED = 'SEARCH_ORDER_FAILED';

export const APPLY_ORDER = 'APPLY_ORDER';
export const APPLY_ORDER_REQUEST = 'APPLY_ORDER_REQUEST';
export const APPLY_ORDER_SUCCESS = 'APPLY_ORDER_SUCCESS';
export const APPLY_ORDER_FAILED = 'APPLY_ORDER_FAILED';

export const SET_LOADING = 'SET_LOADING';
export const ERROR_OCCURRED = 'ERROR_OCCURRED';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const getOrders = (cb) => ({type: GET_ORDERS, cb});

export const getOrdersRequest = () => ({type: GET_ORDERS_REQUEST});

export const getOrdersSuccess = (data) => ({type: GET_ORDERS_SUCCESS, data});

export const getOrdersFailed = (data) => ({type: GET_ORDERS_FAILED, data});


export const setFilteredOrders = (data) => ({type: SET_FILTERED_ORDERS, data});


export const searchOrder = (payload) => ({type: SEARCH_ORDER, payload});

export const searchOrderRequest = () => ({type: SEARCH_ORDER_REQUEST});

export const searchOrderSuccess = (data) => ({type: SEARCH_ORDER_SUCCESS, data});

export const searchOrderFailed = (data) => ({type: SEARCH_ORDER_FAILED, data});


export const applyOrder = (payload, cb) => ({type: APPLY_ORDER, payload, cb});

export const applyOrderRequest = () => ({type: APPLY_ORDER_REQUEST});

export const applyOrderSuccess = (data) => ({type: APPLY_ORDER_SUCCESS, data});

export const applyOrderFailed = (data) => ({type: APPLY_ORDER_FAILED, data});


export const setLoading = payload => ({type: ERROR_OCCURRED, payload});

export const errorOccurred = payload => ({type: ERROR_OCCURRED, payload});

export const clearError = () => ({type: CLEAR_ERROR});
