import {API} from '@utils/axiosInstances';
import alert from '@utils/alert';

import {
    GET_INVOICES,
    getInvoicesRequest,
    getInvoicesSuccess,
    getInvoicesFailed,
    CREATE_FIXED_ASSET,
    createFixedAssetRequest,
    createFixedAssetSuccess,
    createFixedAssetFailed,
    FETCH_NEW_FA_DATA,
    fetchNewFaDataRequest,
    fetchNewFaDataSuccess,
    fetchNewFaDataFailed,
    GET_PRODUCT_SUB_GROUPS,
    getProductSubGroupsRequest,
    getProductSubGroupsSuccess,
    getProductSubGroupsFailed,
    GET_PRODUCT_SUB_SUB_GROUPS,
    getProductSubSubGroupsRequest,
    getProductSubSubGroupsSuccess,
    getProductSubSubGroupsFailed,
    DELETE_FIXED_ASSET,
    deleteFixedAssetRequest,
    deleteFixedAssetSuccess,
    deleteFixedAssetFailed,
    UPDATE_FIXED_ASSET,
    updateFixedAssetRequest,
    updateFixedAssetSuccess,
    updateFixedAssetFailed
} from "@redux/actions/FixedAssets.actions";

import {ERROR_RESPONSE} from "@utils/constants/error.config";
import {takeLatest, put, call, select} from 'redux-saga/effects';

export function* getInvoices() {
    yield takeLatest(GET_INVOICES, getInvoicesAsync);
}

export function* deleteFixedAsset() {
    yield takeLatest(DELETE_FIXED_ASSET, deleteFixedAssetAsync);
}

export function* createFixedAsset() {
    yield takeLatest(CREATE_FIXED_ASSET, createFixedAssetAsync);
}

export function* updateFixedAsset() {
    yield takeLatest(UPDATE_FIXED_ASSET, updateFixedAssetAsync);
}

export function* fetchNewFaData() {
    yield takeLatest(FETCH_NEW_FA_DATA, fetchNewFaDataAsync);
}

export function* getProductSubGroups() {
    yield takeLatest(GET_PRODUCT_SUB_GROUPS, getProductSubGroupsAsync);
}

export function* getProductSubSubGroups() {
    yield takeLatest(GET_PRODUCT_SUB_SUB_GROUPS, getProductSubSubGroupsAsync);
}

export function* getInvoicesAsync() {
    try {
        yield put(getInvoicesRequest());
        const response = yield call(() => API.get(`/imicryl-expo/invoices`));
        if (response.data) {
            yield put(getInvoicesSuccess(response?.data));
        }
    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                yield put(getInvoicesFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(getInvoicesFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* deleteFixedAssetAsync(action) {
    try {
        const selectedCounting = yield select(state => state.core.selectedCounting);
        yield put(deleteFixedAssetRequest());
        const response = yield call(() => API.delete(`/fixed-assets/${selectedCounting.location.value}/${action.payload}`));
        if (response.data) {
            yield put(deleteFixedAssetSuccess(response?.data));
            yield call(getInvoicesAsync);
        }
    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                yield put(deleteFixedAssetFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(deleteFixedAssetFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* createFixedAssetAsync(action) {
    try {
        const selectedCounting = yield select(state => state.core.selectedCounting);
        yield put(createFixedAssetRequest());
        const response = yield call(() => API.post(`/fixed-assets/${selectedCounting.location.value}`, JSON.stringify({
            ...action.payload,
            branchId: selectedCounting.branch.value,
            unit: selectedCounting.unit.value,
            subUnit: selectedCounting.subUnit.value
        })));
        if (response.data) {
            yield put(createFixedAssetSuccess(response?.data));
            if (action.cb) {
                action.cb();
            }
        }
    } catch (error) {
        const {response} = error;
        console.log('ERROR!', response);
        if (response) {
            if (response.data.error) {
                alert('error', {description: response.data.message});
                yield put(createFixedAssetFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(createFixedAssetFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* updateFixedAssetAsync(action) {
    try {
        const selectedCounting = yield select(state => state.core.selectedCounting);
        yield put(updateFixedAssetRequest());
        const response = yield call(() => API.put(`/fixed-assets/${selectedCounting.location.value}/${action.id}`, JSON.stringify({
            ...action.payload,
            branchId: selectedCounting.branch.value,
            unit: selectedCounting.unit.value,
            subUnit: selectedCounting.subUnit.value
        })));
        if (response.data) {
            yield put(updateFixedAssetSuccess(response?.data));
            if (action.cb) {
                action.cb();
            }
        }
    } catch (error) {
        const {response} = error;
        console.log('ERROR!', response);
        if (response) {
            if (response.data.error) {
                alert('error', {description: response.data.message});
                yield put(updateFixedAssetFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(updateFixedAssetFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* fetchNewFaDataAsync() {
    try {
        const selectedCounting = yield select(state => state.core.selectedCounting);
        yield put(fetchNewFaDataRequest());

        const employees = yield call(() => API.get(`/employees/${selectedCounting.location.value}`));
        const productGroups = yield call(() => API.get(`/product-groups/${selectedCounting.location.value}`));
        const productSubGroups = yield call(() => API.get(`/product-sub-groups/${selectedCounting.location.value}`));
        const productSubSubGroups = yield call(() => API.get(`/product-sub-sub-groups/${selectedCounting.location.value}`));

        if (employees.data && productGroups.data && productSubGroups.data && productSubSubGroups.data) {
            yield put(fetchNewFaDataSuccess({
                employees: employees.data,
                productGroups: productGroups.data,
                productSubGroups: productSubGroups.data,
                productSubSubGroups: productSubSubGroups.data
            }));
        }

    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                yield put(fetchNewFaDataFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(fetchNewFaDataFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* getProductSubGroupsAsync(action) {
    try {

        yield put(getProductSubGroupsRequest());

        const productSubGroups = yield call(() => API.get(`/product-sub-groups/${action.payload}`));

        if (productSubGroups.data) {
            yield put(getProductSubGroupsSuccess(productSubGroups.data));
        }

    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                yield put(getProductSubGroupsFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(getProductSubGroupsFailed(ERROR_RESPONSE(error)));
        }
    }
}

export function* getProductSubSubGroupsAsync(action) {
    try {

        yield put(getProductSubSubGroupsRequest());

        const productSubGroups = yield call(() => API.get(`/product-sub-sub-groups/${action.payload}`));

        if (productSubGroups.data) {
            yield put(getProductSubSubGroupsSuccess(productSubGroups.data));
        }

    } catch (error) {
        const {response} = error;
        if (response) {
            if (response.data.error) {
                yield put(getProductSubSubGroupsFailed(ERROR_RESPONSE(response.data)));
            }
        } else {
            yield put(getProductSubSubGroupsFailed(ERROR_RESPONSE(error)));
        }
    }
}
